var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.formattedData)?_c('div',[_c('div',{staticClass:"header"},[(_vm.formattedData)?_c('ReviewCard',{staticClass:"reviewCard",attrs:{"image":_vm.formattedData.srcImage,"target":_vm.formattedData.target,"mainCategory":_vm.formattedData.mainCategory,"subCategory":_vm.formattedData.subCategory,"title":_vm.formattedData.title,"author":_vm.formattedData.cardInfo.author_books ||
					_vm.formattedData.cardInfo.direction ||
					_vm.formattedData.cardInfo.author_music,"buttonUrl":_vm.formattedData.cardInfo.button_url,"buttonLabel":_vm.formattedData.cardInfo.button_label}}):_vm._e(),_c('div',{staticClass:"infos"},_vm._l((_vm.infoArray),function(info){return _c('div',{key:info.key},[(_vm.formattedData.cardInfo[info.key])?_c('span',{staticClass:"label",style:({ color: _vm.pageColor })},[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$t(info.label))))]):_vm._e(),(_vm.formattedData.cardInfo[info.key])?_c('span',[_vm._v(_vm._s(_vm._f("addComma")(_vm.formattedData.cardInfo[info.key],info.key)))]):_vm._e()])}),0),_c('div',{staticClass:"infosMobile"},[_c('v-expansion-panels',{attrs:{"flat":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"pa-0",scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',{class:'color-' + _vm.formattedData.target.slug},[_vm._v(" mdi-plus ")])]},proxy:true}],null,false,3188967551)},[_c('div',{class:'name color-' + _vm.formattedData.target.slug},[_vm._v(" INFO ")]),_c('div',{class:'separator background-' + _vm.formattedData.target.slug})]),_c('v-expansion-panel-content',{staticClass:"pa-0"},_vm._l((_vm.infoArray),function(info){return _c('div',{key:info.key},[(_vm.formattedData.cardInfo[info.key])?_c('span',{staticClass:"label",style:({ color: _vm.pageColor })},[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$t(info.label))))]):_vm._e(),(_vm.formattedData.cardInfo[info.key])?_c('span',[_vm._v(_vm._s(_vm._f("addComma")(_vm.formattedData.cardInfo[info.key],info.key)))]):_vm._e()])}),0)],1)],1)],1),_c('br',{staticClass:"clear"})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":_vm.$vuetify.breakpoint.mobile ? 12 : 8,"offset":_vm.$vuetify.breakpoint.mobile ? 0 : 2}},[(
				_vm.formattedData.cardInfo.review_content_books ||
					_vm.formattedData.cardInfo.review_content_music ||
					_vm.formattedData.cardInfo.review_content_movies
			)?_c('div',{staticClass:"quote-wrapper"},[_c('div',{class:'font-size-20 quote background-light-' + _vm.formattedData.target.slug},[_c('h3',{staticClass:"font-size-20"},[_vm._v("Te lo consiglio perché")]),_c('div',{domProps:{"innerHTML":_vm._s(
						_vm.formattedData.cardInfo.review_content_books ||
							_vm.formattedData.cardInfo.review_content_music ||
							_vm.formattedData.cardInfo.review_content_movies
					)}})])]):_vm._e(),(
				_vm.formattedData.cardInfo.review_author_books ||
					_vm.formattedData.cardInfo.review_author_music ||
					_vm.formattedData.cardInfo.direction
			)?_c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticClass:"text-right author",style:({ color: _vm.pageColor })},[_vm._v(" "+_vm._s(_vm.formattedData.cardInfo.review_author_books || _vm.formattedData.cardInfo.review_author_music || _vm.formattedData.cardInfo.direction)+" "),_c('v-icon',{attrs:{"color":_vm.pageColor}},[_vm._v("mdi-circle-medium")])],1)]):_vm._e()])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }