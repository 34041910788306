<template>
	<div v-if="formattedData">
		<div class="header">
			<ReviewCard
				v-if="formattedData"
				class="reviewCard"
				:image="formattedData.srcImage"
				:target="formattedData.target"
				:mainCategory="formattedData.mainCategory"
				:subCategory="formattedData.subCategory"
				:title="formattedData.title"
				:author="
					formattedData.cardInfo.author_books ||
						formattedData.cardInfo.direction ||
						formattedData.cardInfo.author_music
				"
				:buttonUrl="formattedData.cardInfo.button_url"
				:buttonLabel="formattedData.cardInfo.button_label"
			></ReviewCard>
			<div class="infos">
				<div v-for="info in infoArray" :key="info.key">
					<span v-if="formattedData.cardInfo[info.key]" class="label" :style="{ color: pageColor }">{{
						$t(info.label) | capitalize
					}}</span>
					<span v-if="formattedData.cardInfo[info.key]">{{
						formattedData.cardInfo[info.key] | addComma(info.key)
					}}</span>
				</div>
			</div>
			<div class="infosMobile">
				<v-expansion-panels flat>
					<v-expansion-panel>
						<v-expansion-panel-header class="pa-0">
							<template v-slot:actions>
								<v-icon :class="'color-' + formattedData.target.slug">
									mdi-plus
								</v-icon>
							</template>
							<div :class="'name color-' + formattedData.target.slug">
								INFO
							</div>
							<div :class="'separator background-' + formattedData.target.slug"></div>
						</v-expansion-panel-header>
						<v-expansion-panel-content class="pa-0"
							><div v-for="info in infoArray" :key="info.key">
								<span
									v-if="formattedData.cardInfo[info.key]"
									class="label"
									:style="{ color: pageColor }"
									>{{ $t(info.label) | capitalize }}</span
								>
								<span v-if="formattedData.cardInfo[info.key]">{{
									formattedData.cardInfo[info.key] | addComma(info.key)
								}}</span>
							</div></v-expansion-panel-content
						>
					</v-expansion-panel>
				</v-expansion-panels>
			</div>
			<br class="clear" />
		</div>
		<v-col :cols="$vuetify.breakpoint.mobile ? 12 : 8" :offset="$vuetify.breakpoint.mobile ? 0 : 2" class="pa-0">
			<div
				v-if="
					formattedData.cardInfo.review_content_books ||
						formattedData.cardInfo.review_content_music ||
						formattedData.cardInfo.review_content_movies
				"
				class="quote-wrapper"
			>
				<div :class="'font-size-20 quote background-light-' + formattedData.target.slug">
					<h3 class="font-size-20">Te lo consiglio perché</h3>

					<div
						v-html="
							formattedData.cardInfo.review_content_books ||
								formattedData.cardInfo.review_content_music ||
								formattedData.cardInfo.review_content_movies
						"
					/>
				</div>
			</div>
			<div
				v-if="
					formattedData.cardInfo.review_author_books ||
						formattedData.cardInfo.review_author_music ||
						formattedData.cardInfo.direction
				"
			>
				<div class="text-right author" v-show="false" :style="{ color: pageColor }">
					{{
						formattedData.cardInfo.review_author_books ||
							formattedData.cardInfo.review_author_music ||
							formattedData.cardInfo.direction
					}}
					<v-icon :color="pageColor">mdi-circle-medium</v-icon>
				</div>
			</div>
		</v-col>
	</div>
</template>

<script>
import { formatCategoriesAndTargets } from "@/mixins/formatCategoriesAndTargets";
import ReviewCard from "@/components/ui/ReviewCard";
import { GET_POST, GET_POST_PREVIEW } from "@/queries/Posts.js";
export default {
	mixins: [formatCategoriesAndTargets],
	props: {
		postSlug: {
			type: String,
			default: null,
			required: true,
		},
	},
	components: {
		ReviewCard,
	},
	computed: {
		pageColor() {
			return this.colorsObject[this.formattedData.target.slug];
		},
	},
	data() {
		return {
			infoArray: [
				{ label: "year", key: "year" },
				{ label: "publisher", key: "publisher" },
				{ label: "genre", key: "tags" },
				{ label: "suitedFor", key: "suitedFor" },
			],
			formattedData: null,
		};
	},
	filters: {
		addComma: function(value, key) {
			if (key !== "tags") {
				return value;
			}
			let message = "";
			for (var i = 0; i < value.length; i++) {
				if (i !== value.length - 1) {
					message += value[i].name + ", ";
				} else {
					message += value[i].name;
				}
			}
			return message;
		},
	},
	methods: {
		getIconColor(index, slidingPagesSlug) {
			return this.panel === index ? this.colorsObject[slidingPagesSlug] : "white";
		},
	},
	async mounted() {
		if (this.postSlug) {
			let query;
			let isPreview = this.$route.query && this.$route.query.preview;
			if (isPreview) {
				query = {
					query: GET_POST_PREVIEW(this.postSlug)
				};
			} else {
				query = {
					query: GET_POST(this.postSlug)
				};
			}
			
			let data = await this.$apollo.query(query);

			if (isPreview) {
				data = data && data.data && data.data.post || false;
			} else {
				data = data && data.data && data.data.posts && data.data.posts.nodes && data.data.posts.nodes[0]? data.data.posts.nodes[0] : false;
			}
			data.blocks = JSON.parse("[" + data.blocksJSON + "]")[0];
			let newData = this.formatCategoriesAndTargets(data)[0];
			let cardInfo = JSON.parse(newData.blocks[0].attributes.fields);
			cardInfo["year"] = cardInfo.year_books
				? cardInfo.year_books
				: cardInfo.year_movies
				? cardInfo.year_movies
				: cardInfo.year_music;
			cardInfo["tags"] = newData.tags.nodes;
			cardInfo["suitedFor"] = newData.target.name;
			this.formattedData = {
				title: newData.title,
				cardInfo: cardInfo,
				blocks: newData.blocks.slice(1, newData.length),
				srcImage: newData.featuredImage
					? newData.featuredImage.node.sourceUrl
					: "/wp-content/uploads/2020/11/no-iamge-placeholder.jpg",
				target: newData.target,
				subCategory: newData.subCategory,
				mainCategory: newData.mainCategory,
			};
		}
	},
};
</script>

<style lang="scss" scoped>
.label {
	font-size: $font-size-14;
	text-transform: uppercase;
}
.page {
	margin: auto;
}
.card {
	min-height: 1000px;
}
.reviewCard {
	float: left;
	margin-right: 50px;
	width: 70%;
}
.infos {
	margin-top: $spacing-0;
	width: 15%;
	float: right;
}
.infos span {
	display: inline-block;
	width: 100%;
}
.header {
	padding: $spacing-1;
}
.quote h3 {
	margin-bottom: $spacing-0;
}
.quote {
	font-weight: normal;
	padding: $spacing-1;
	border-radius: 50px 0 50px 50px;
	word-break: break-word;
}
.author {
	color: $green;
	padding-top: 10px;
	margin-right: -10px;
}
.infosMobile {
	display: none;
}
.separator {
	height: 2px;
	flex: 100 !important;
	display: inline-block;
}

@media only screen and (max-width: 768px) {
	.header {
		padding: $spacing-0 $spacing-0 0 $spacing-0;
	}
	.infosMobileHeader {
		padding: 0 $spacing-0;
	}
	.reviewCard {
		width: 100%;
	}

	.infos {
		float: left;
		display: inline-flex;
		width: 100%;
		margin-top: 2rem;
		justify-content: center;
	}
	.row {
		display: inline-block;
		width: 100%;
	}

	.infos {
		display: none;
	}
	.infosMobile {
		display: block;
		font-weight: bold;
	}
	.infosMobile span {
		width: 100%;
		display: inline-block;
	}

	.quote {
		padding: $spacing-0;
		border-radius: 25px 0 25px 25px;
	}

	.content {
		padding: $spacing-0 0;
	}
}

@media only screen and (max-width: 550px) {
	.quote-wrapper {
		width: 100%;
		position: relative;
	}
}
</style>
