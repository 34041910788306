<template>
	<div>
		<v-card class="innerCard">
			<div class="contentCard">
				<div class="headerCard">
					<v-row>
						<span
							:class="'target color-' + target.slug"
							class="pa-0"
							>{{ subCategory ? subCategory.name : "" }} &bull;
							{{ target ? target.name : "" }}</span
						>
					</v-row>
					<v-row>
						<span class>di {{ author }}</span>
					</v-row>
					<v-row>
						<span
							class="font-weight-bold titleCard font-size-35 my-8 pr-8"
							>{{ title }}</span
						>
					</v-row>
					<v-row v-if="buttonUrl">
						<v-btn
							depressed
							@click="openLinkInNewTab(buttonUrl)"
							:color="colorsObject[target.slug]"
							class="loanButton white--text"
							name="Recensione"
							>{{ buttonLabel }}</v-btn
						>
					</v-row>
				</div>
				<div class="articleCard">
					<div v-show="show" style="position: relative">
						<v-slide-y-reverse-transition>
							<img
								v-show="show"
								class="image"
								:src="image"
								@load="show = true"
							/>
						</v-slide-y-reverse-transition>
						<v-fade-transition>
							<CategoryShape
								v-if="subCategory && target"
								class="categoryShape"
								:category="subCategory.slug"
								:target="target.slug"
							/>
						</v-fade-transition>
					</div>
				</div>
				<br class="clear" />
			</div>
		</v-card>
		<div class="contentCardMobile">
			<span :class="'target color-' + target.slug" class="pa-0"
				>{{ subCategory ? subCategory.name : "" }} &bull;
				{{ target ? target.name : "" }}</span
			><br />
			<span class>di {{ author }}</span>
			<div class="headerMobile">
				<div v-show="show">
					<v-slide-y-reverse-transition>
						<img
							v-show="show"
							class="image"
							:src="image"
							@load="show = true"
						/>
					</v-slide-y-reverse-transition>
					<v-fade-transition>
						<CategoryShape
							v-if="subCategory && target"
							class="categoryShape"
							:category="subCategory.slug"
							:target="target.slug"
						/>
					</v-fade-transition>
				</div>
				<span
					class="font-weight-bold titleCard font-size-35 my-8 pr-8"
					>{{ title }}</span
				>
			</div>
		</div>
	</div>
</template>

<script>
import CategoryShape from "@/components/ui/CategoryShape.vue";
export default {
	components: {
		CategoryShape
	},
	props: {
		image: {
			type: String,
			required: true
		},
		author: {
			type: String,
			required: true
		},
		target: {
			type: Object,
			required: true
		},
		mainCategory: {
			type: Object,
			required: true
		},
		subCategory: {
			type: Object,
			required: true
		},
		title: {
			type: String,
			required: true
		},
		buttonUrl: {
			type: String,
			required: false
		},
		buttonLabel: {
			type: String,
			default: "Prestito",
			required: false
		}
	},
	data() {
		return {
			show: false
		};
	}
};
</script>

<style lang="scss" scoped>
.articleCard {
	width: 30%;
	display: inline-block;
}
.categoryShape {
	position: absolute;
	right: 0;
	left: 0;
	top: 15%;
	bottom: 15%;
	transform: translateX(40%);
}
.contentCard {
	padding: $spacing-0;
}
.headerCard {
	width: 70%;
	float: left;
}
.image {
	position: relative;
	z-index: 1;
	max-width: 100%;
}
#app .innerCard {
	border-radius: 12px;
	box-shadow: 17px 17px 35px -5px rgba(0, 0, 0, 0.2);
	min-height: 200px;
}
.loanButton {
	border-radius: 50px;
	border-top-left-radius: 0;
}
.negative-offset {
	margin-right: -$spacing-1;
}
.target {
	font-size: $font-size-14;
	text-transform: uppercase;
}
.contentCardMobile {
	display: none;
}
@media only screen and (max-width: 768px) {
	.contentCardMobile {
		display: block;
	}
	.innerCard {
		display: none;
	}

	.headerMobile {
		position: relative;
		margin: $spacing-0 0;
	}
	.categoryShape {
		transform: none;
	}
	.headerMobile img {
		max-height: 80vw;
	}
}
</style>
